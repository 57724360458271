import React, { useContext, useEffect, useState, useRef } from "react";
import style from "./Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../Button/Button.js";
import { QtdContext } from "../../../context/qtdContext.js";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';

import { RiDiscountPercentFill } from "react-icons/ri";
import { CiPercent } from "react-icons/ci";
import { confirmAlert } from 'react-confirm-alert'; // Import
import './react-confirm-alert.css'; // Import css
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { Store } from 'react-notifications-component';
import ProgressBar from "@ramonak/react-progress-bar";
import 'animate.css/animate.min.css';
import { BiSolidSave } from "react-icons/bi";
import { RiCheckboxBlankCircleLine } from "react-icons/ri";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import ComfirmReportModal from "./ComfirmReportModal.js";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FcOk, FcUp } from "react-icons/fc";
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { MdOutlineRadioButtonUnchecked, MdOutlineCheckCircleOutline } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';
import AddContrAgentsRashodModal from "../ContrAgents/AddContrAgentsRashodModal.js";
import EditContrAgentsRashodModal from "../ContrAgents/EditContrAgentsRashodModal.js";
const AvansReport = ({ shop, selectedReport }) => {
  const { shops, incosations, setIncosations, contragentsPrihods, editCARashod, seteditCARashod, avansReports, setAvansReports, addCARashod, setaddCARashod, contragents, setAdmin, setComfirmReportModal, saleReports, setSaleReports, avansReportModal, setAvansReportModal, saleReportForms, saleReportSteps, setSaleReportSteps, scladShops, reciepts, sclad, setEditShopModal, admins, Notify, admin } = useContext(QtdContext);
  const [loading, seloading] = useState(false)
  const [date, setdate] = useState('')
  const [thisNakladnaya, setthisNakladnaya] = useState('')
  const [thisReport, setThisReport] = useState(selectedReport)
  const [incosationsReport, setIncosationsReport] = useState(incosations.filter(pr => pr.avans_id == selectedReport.id))
  const [summIncasations, setSummIncasations] = useState(0)
  const regex = /^\d*\.?\d{0,2}$/;
  function formatDate(dateString) {
    const date = new Date(dateString);
    const months = [
      'янв', 'фев', 'мар', 'апр', 'май', 'июн',
      'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }
  let rep = []
  let titel = ''
  const revenues = thisReport.revenues ? typeof thisReport.revenues == 'string' ? JSON.parse(thisReport.revenues) : thisReport.revenues : {
    z_otchet: 0,
    nal: 0,
    beznal: 0,
    rashods: []
  }
  let summinc = 0
  incosationsReport.map(gt => {
    if (gt.confirm) {
      summinc = Number(summinc) + Number(gt.money)

    }

  })
  let on = 0
  let ob = 0
  revenues.rashods.map(gt => {


    const nakladnaya = contragentsPrihods.find(t => t.id == gt)
    on = Number(on) + Number(nakladnaya.nal)
    ob = Number(ob) + Number(nakladnaya.beznal)
  })
  const kassa = thisReport.kassa ? typeof thisReport.kassa == 'string' ? JSON.parse(thisReport.kassa) : thisReport.kassa : {
    incosations: [],
    //привязать кассу точки
    spendnal: on,
    spendbeznal: ob,
    startweek: 0,
    endweek: 0,
    ostatok: 0
  }

  // { ...oldkassa2, spendallnal: renderkassa.spendallnal, spendallbeznal: renderkassa.spendallbeznal, endweek: oldkassa2.startweek + renderkassa.nal - renderkassa.spendallnal }
  console.log(revenues, kassa)

  const Incasation = async () => {
    try {
      seloading(true)
      const ret = {
        avans_id: thisReport.id,
        admin_id: admin.id,
        shop_id: shop.id,
        smena_id: thisReport.smena_id,
        money: summIncasations
      }
      const newProd = await $api.post(`${BACKURL}/api/incosations/create`, ret)
      setIncosations([...incosations, newProd.data.incosation])
      setThisReport(newProd.data.avansreport)
      setIncosationsReport([...incosationsReport, newProd.data.incosation])
      setAvansReports(avansReports.map(pr => {
        if (pr.id == newProd.data.avansreport.id) {
          return newProd.data.avansreport
        } else {
          return pr
        }
      }))
      console.log(admin.id,
        shop.id,
        thisReport.smena_id,
        summIncasations,
        newProd
      )

      seloading(false)

    } catch (e) {
      console.log(e)
      seloading(false)

    }
  }
  const save = async (rep) => {
    try {
      seloading(true)
      const newProd = await $api.post(`${BACKURL}/api/avansreports/save/${rep.id}`,
        { thisReport: { ...rep, kassa: kassa, revenues: revenues }, admin: admin.id, shop_id: shop.id }
      )
      console.log(newProd)
      seloading(false)
      Notify.addNotification({
        title: "Готово!",
        message: "Авансовый отчет успешно сохранен!",
        type: "success",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });

    } catch (e) {
      seloading(false)
      Notify.addNotification({
        title: "Ошибка!",
        message: "Произошла непредвиденная ошибка!",
        type: "danger",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
      console.log(e)
    }
  }

  const deleteRashod = async (id) => {
    try {
      seloading(true)
      const newProd = await $api.post(`${BACKURL}/api/contragentsrashod/delete/${id}`, { avansreport_id: thisReport.id, shop_id: shop.id })
      setAvansReports(avansReports.map(pr => {
        if (pr.id == newProd.data.id) {
          return newProd.data
        } else return pr
      }))
      setThisReport(newProd.data)
      seloading(false)
    } catch (e) {
      seloading(false)
      console.log(e)
    }
  }

  const confirm = async (id) => {

    try {
      seloading(true)

      const newProd = await $api.get(`${BACKURL}/api/incosations/confirm/${id}`)
      setIncosations(incosations.map(pr => {
        if (pr.id == id) {
          return newProd.data
        } else {
          return pr
        }
      }))
      setIncosationsReport(incosations.filter(pr => {
        if (pr.id == id) {
          return newProd.data
        } else if (pr.avans_id == selectedReport.id) {
          return pr
        }
      }))


      seloading(false)

    } catch (e) {
      console.log(e)
      seloading(false)

    }

  }
  console.log(thisReport)
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "c2ontaidnerwsw12464qq" && setAvansReportModal(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };

  function getWeekDates(weekNumber) {
    const today = new Date();
    const currentYear = today.getFullYear();
    const daysInWeek = 7;

    // Get the week number if not provided
    if (weekNumber === null) {
      const startOfYear = new Date(currentYear, 0, 1);
      const pastDaysOfYear = (today - startOfYear) / 86400000;
      weekNumber = Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7) - 1;
    }

    // Find the first day of the year
    const firstDayOfYear = new Date(currentYear, 0, 1);
    const dayOfWeek = firstDayOfYear.getDay(); // 0 (Sunday) - 6 (Saturday)

    // Find the first Monday of the year
    const firstMonday = new Date(firstDayOfYear);
    firstMonday.setDate(firstMonday.getDate() + (dayOfWeek === 0 ? 1 : 8 - dayOfWeek));

    // Calculate the start date of the given week
    const startDate = new Date(firstMonday);
    startDate.setDate(startDate.getDate() + (weekNumber - 1) * daysInWeek);

    // Collect all days of the week
    const days = [];
    for (let i = 0; i < daysInWeek; i++) {
      const currentDate = new Date(startDate);
      currentDate.setDate(startDate.getDate() + i);
      // Format the date as "D/MM/YY"
      const formattedDate = `${currentDate.getDate()}/${(currentDate.getMonth() + 1).toString().padStart(2, '0')}/${currentDate.getFullYear().toString().slice(-2)}`;
      days.push({ date: formattedDate, month: currentDate.getMonth() + 1 });
    }

    // Split the days into different months
    const result = {};
    days.forEach(day => {
      const month = day.month;
      if (!result[month]) {
        result[month] = { week: { week: weekNumber, month: month, days: [] } };
      }
      result[month].week.days.push(day.date);
    });

    return Object.values(result);
  }

  // const weekNumber = 4;
  const InnerAccordion = ({ contr, gt, list }) => {
    const adm = admins.find(pr => pr.id == gt.admin_id)
    return (
      <Accordion allowZeroExpanded style={{ width: '100%' }}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton><>
              <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'space-evenly' }}>
                <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-evenly' }}>
                  <div>
                    {contr.name}

                  </div>
                  <div>
                    <b>{adm.fullname}</b>
                  </div>
                </div>

                <div style={{ display: 'flex', flexDirection: "column" }}>
                  <div>
                    Наличныйе:<b>{gt.nal ? `${gt.nal} руб` : '0 руб'}</b>

                  </div>
                  <div>
                    Безналичные: <b>{gt.beznal ? `${gt.beznal} руб` : '0 руб'}</b>

                  </div>
                </div>

              </div>

            </>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel style={{ background: 'wheat' }}>
            {(admin.role == 'Аминистратор' || admin.role == 'AllRights') && (


              <div style={{ display: 'flex', flexDirection: "row" }}>
                {/* <Button text='Изменить' background='gold'
                  width='100%' height='30px'
                  onClick={(e) => {
                    setdate(pr.date)
                    setthisNakladnaya(gt)
                    seteditCARashod(true)
                  }} /> */}
                <Button text='Удалить' background='red'
                  width='100%' height='30px'
                  onClick={(e) => deleteRashod(gt.id)} />
              </div>
            )}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center", justifyContent: "center" }}>

              {list.map((product, index) => {
                // console.log(product)
                return <div style={{ display: 'flex', width: "100%", flexDirection: 'row', alignItems: "center", justifyContent: "space-between" }}>
                  <div>
                    <b>{index + 1})</b>
                  </div>
                  <div>
                    {product.name}
                  </div>
                  <div>
                    <b>{product.qty}</b>{product.mnt}
                  </div>


                </div>
              })}
            </div>

          </AccordionItemPanel>
        </AccordionItem>

      </Accordion>
    )
  };
  return (
    <>
      {/* <ReactNotifications /> */}
      {avansReportModal && (
        <div
          onClick={closeModal}
          id="c2ontaidnerwsw12464qq" className={style.modal_container}
        // style={{backgroundImage: "url('../../../pages/auth/unnamed-2.jpg')"}}
        >
          <div className={style.borders2} style={{ height: 'inherit' }}>
            <h2 id={'select'} style={{ color: "white" }}> {shop.name}</h2>
            <h5>{titel}</h5>
            {/* <h3 style={{ color: "white" }}>{(totlaMoney - totalsale).toFixed()} РУБ</h3> */}
            <div className={style.nextitem}>
              {thisReport.status == "Открыт" && (
                <div className={style.uuu} style={{ flexDirection: 'row' }}>
                  <BiSolidSave style={{ color: "white" }} onClick={() => save(thisReport)}></BiSolidSave>

                </div>
              )}

              < Button
                text="Выход"
                background="var(--primary-color)"
                color="white"
                width="100px"
                onClick={() => {
                  setAvansReportModal(false)

                }}
              />
            </div>
            <div style={{ display: 'flex', width: "100%", flexDirection: 'column', textAlign: "center" }} >
              <Tabs >
                <TabList>
                  <Tab style={{ background: 'none', border: 'none', color: "white" }} key={"Продажи"}><h5>Выручка</h5></Tab>

                  <Tab style={{ background: 'none', border: 'none', color: "white" }} key={'Счетчики'}><h5>Расходы</h5></Tab>
                  <Tab style={{ background: 'none', border: 'none', color: "white" }} key={'Остатки'}><h5>Касса</h5></Tab>
                </TabList>
                {/* counters */}
                <TabPanel>
                  {/* <table style={{ width: '100%' }}> */}

                  <div style={{ color: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', overflowX: 'hidden' }}>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <div style={{ width: '33%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <b>Дата</b>
                      </div>
                      <div style={{ width: '33%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <b>Менеджер</b>
                      </div>
                      <div style={{ width: '33%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <b>Часы</b>
                      </div>
                    </div>




                    <div
                      style={{ width: '100%', marginBottom: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                      <div style={{ width: '33%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <h5>{thisReport.createdAt}</h5>
                      </div>
                      <div style={{ width: '33%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <h5>{thisReport.admin_id ? admins.find(tt => tt.id == thisReport.admin_id).fullname : admin.fullname}</h5>
                      </div>
                      <div style={{ width: '33%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <input
                          type="number"
                          inputMode="decimal" // для открытия цифровой клавиатуры на мобильных устройствах
                          pattern="^\d*\.?\d{0,2}$" // дополнительная валидация
                          step="1" // шаг ввода для ограничения на два знака после запятой
                          min="0" // минимальное значение
                          className={style.kassainput} onChange={(e) => {

                            const newavansReports = avansReports.map(pr1 => {

                              if (pr1.id == selectedReport.id) {
                                setThisReport({ ...thisReport, revenues: { ...revenues, hours: e.target.value } })
                                return { ...thisReport, revenues: { ...revenues, hours: e.target.value } }
                              } else {
                                return pr1
                              }
                            })
                            // console.log(newavansReports)
                            setAvansReports(newavansReports)
                          }}
                          disabled={thisReport.status == "Открыт" ? false : true}
                          value={revenues.hours}
                          style={{ width: "50px" }} placeholder="Часы" />
                      </div>
                    </div>
                    <div style={{ width: '100%', marginBottom: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                      <div style={{ width: '33%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <label>Z-Отчет</label>
                        <input
                          type="number"
                          inputMode="decimal" // для открытия цифровой клавиатуры на мобильных устройствах
                          pattern="^\d*\.?\d{0,2}$" // дополнительная валидация
                          step="0.01" // шаг ввода для ограничения на два знака после запятой
                          min="0" // минимальное значение
                          disabled={thisReport.status == "Открыт" ? false : true}

                          className={style.kassainput}
                          onChange={(e) => {

                            const newavansReports = avansReports.map(pr1 => {

                              if (pr1.id == selectedReport.id) {
                                setThisReport({ ...thisReport, revenues: { ...revenues, z_otchet: e.target.value } })
                                return { ...thisReport, revenues: { ...revenues, z_otchet: e.target.value } }
                              } else {
                                return pr1
                              }
                            })
                            // console.log(newavansReports)
                            setAvansReports(newavansReports)
                          }}

                          value={revenues.z_otchet} style={{ width: "50%" }} placeholder="Z-Отчет" />
                      </div>
                      <div style={{ width: '33%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <label>Наличные</label>

                        <input

                          className={style.kassainput}
                          value={(Number(revenues.z_otchet) - Number(revenues.beznal)).toFixed(2)} style={{ width: "50%" }} placeholder="Наличные" disabled />
                      </div>
                      <div style={{ width: '33%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <label>Безнал</label>

                        <input
                          type="number"
                          inputMode="decimal" // для открытия цифровой клавиатуры на мобильных устройствах
                          pattern="^\d*\.?\d{0,2}$" // дополнительная валидация
                          step="0.01" // шаг ввода для ограничения на два знака после запятой
                          min="0" // минимальное значение
                          className={style.kassainput}
                          disabled={thisReport.status == "Открыт" ? false : true}

                          onChange={(e) => {
                            const newavansReports = avansReports.map(pr1 => {

                              if (pr1.id == selectedReport.id) {
                                setThisReport({ ...thisReport, revenues: { ...revenues, beznal: e.target.value } })
                                return { ...thisReport, revenues: { ...revenues, beznal: e.target.value } }
                              } else {
                                return pr1
                              }
                            })
                            // console.log(newavansReports)
                            setAvansReports(newavansReports)
                          }}




                          value={revenues.beznal} style={{ width: "50%" }} placeholder="Безнал" />
                      </div>
                    </div>


                    <hr style={{ width: '100%', marginBottom: '15px', border: '1px black solid' }} />




                  </div>
                  {/* </tbody>
                  </table> */}
                </TabPanel>
                <TabPanel>
                  {/* <Accordion allowZeroExpanded style={{ width: '100%' }}>


                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel> */}
                  {/* {(new Date(pr.date).getDate() == new Date().getDate()) && admin.role == 'Бариста' && ( */}
                  {thisReport.status == 'Открыт' && (
                    <Button text='+' width='50px' background='gold'
                      onClick={() => {
                        save(thisReport)
                        // setdate(pr.date)
                        setaddCARashod(true)
                      }}
                    />
                  )}

                  {/* )} */}
                  {/* {admin.role !== 'Бариста' && (
                          <Button text='+' width='50px' background='gold'
                            onClick={() => {
                              // setdate(pr.date)
                              setaddCARashod(true)
                            }}
                          />
                        )} */}

                  {revenues.rashods.map(gt => {

                    const nakladnaya = contragentsPrihods.find(t => t.id == gt)
                    // console.log(gt,nakladnaya,contragentsPrihods)
                    const list = nakladnaya.list.map(oo => JSON.parse(oo))
                    const contr = contragents.find(pr => pr.id == nakladnaya.contragent_id)
                    // console.log(nakladnaya, list,contr)

                    return (
                      <>
                        <InnerAccordion contr={contr} gt={nakladnaya} list={list} />

                      </>
                    )
                  })}




                  {/* </AccordionItemPanel>
                    </AccordionItem >


                  </Accordion > */}
                </TabPanel>
                <TabPanel>
                  <h2 style={{ color: 'white' }}>Касса на начало недели</h2>
                  <input value={kassa.startweek} className={style.kassainput} placeholder="Касса на начало недели" />

                  <h2 style={{ color: 'white' }}>Израсходовано всего</h2>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                      <label>Наличные</label>
                      <input value={on ? on : 0} style={{ width: '40%' }} className={style.kassainput} placeholder="Наличные" />

                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                      <label>Безналичные</label>

                      <input value={ob ? ob : 0} style={{ width: '40%' }} className={style.kassainput} placeholder="Безналичные" />

                    </div>


                  </div>
                  <h2 style={{ color: 'white' }}>Касса на конец недели</h2>
                  <input value={Number(kassa.startweek) + Number(Number(revenues.z_otchet) - Number(revenues.beznal)) - on} className={style.kassainput} placeholder="Касса на конец недели" />



                  <h2 style={{ color: 'white' }}>Инкасированно</h2>
                  {incosationsReport.length == 0 && (
                    <input value={'Нет инкасаций'} disabled={admin.role !== 'Аминистратор' && admin.role !== 'AllRights'} className={style.kassainput} placeholder="Инкасированно" />

                  )}
                  {incosationsReport.length > 0 && (
                    <>
                      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center' }}>

                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>

                          <div style={{ display: 'flex', flexDirection: 'row', width: '30%', alignItems: 'center', justifyContent: 'center' }}>
                            <b>Инкосатор</b>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', width: '30%', alignItems: 'center', justifyContent: 'center' }}>
                            <b>Сумма</b>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', width: '30%', alignItems: 'center', justifyContent: 'center' }}>
                            <b>Подтвердить</b>
                          </div>
                        </div>


                        {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: "center" }}>

                          <div>
                            <b>Инкосатор</b>
                          </div>

                          <div>
                            <b>Сумма</b>
                          </div>

                          <div>
                            <b>Подтвердить</b>
                          </div>
                        </div> */}



                        {incosationsReport.map((pr) => {
                          // const inc = incosations.find(hh => hh.id == pr)
                          console.log(pr)
                          const adm = admins.find(a => a.id == pr.admin_id)
                          // const emp = admins.find(a => a.id == pr.employee_id)
                          // const sh = shops.find(a => a.id == pr.shop_id)
                          if (pr) {
                            return <>
                              <div style={{ display: 'flex', color: 'white', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>

                                <div style={{ display: 'flex', color: 'white', flexDirection: 'row', width: '30%', alignItems: 'center', justifyContent: 'center' }}>
                                  {adm.fullname}
                                </div>

                                <div style={{ display: 'flex', color: 'white', flexDirection: 'row', width: '30%', alignItems: 'center', justifyContent: 'center' }}>
                                  {pr.money}руб
                                </div>
                                <div style={{ display: 'flex', color: 'white', flexDirection: 'row', width: '30%', alignItems: 'center', justifyContent: 'center' }}>
                                  {thisReport.status == "Открыт" && !pr.confirm && admin.role == 'Бариста' && <Button text='Подтвердить' background='gold'
                                    width='100%' height='30px'
                                    onClick={() => {
                                      // if (summIncasations > 0) {
                                      confirm(pr.id)
                                      // }

                                    }} />}
                                  {/* {thisReport.status == "Открыт" && (<>



                                  </>) */}
                                  {/* } */}
                                  {thisReport.status == "Открыт" && !pr.confirm && admin.role != 'Бариста' && <>
                                    <Button text='Изменить' background='gold'
                                      width='100%' height='30px'
                                      onClick={() => {
                                        confirm(pr.id)
                                      }} />
                                    <Button text='Удалить' background='gold'
                                      width='100%' height='30px'
                                      onClick={() => {
                                        confirm(pr.id)
                                      }} />
                                  </>}
                                  {pr.confirm && (<>Подтверждено</>)}
                                </div>         </div>

                            </>
                            // <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: "center" }}>

                            //   <div>
                            //    
                            //   </div>

                            //   <div>
                            //     {pr.money}руб
                            //   </div>

                            //   <div>
                            //     <Button text='Подтвердить' background='gold'
                            //       width='100%' height='50px'
                            //       onClick={() => {

                            //       }} />
                            //   </div>
                            // </div>
                          }
                        })}
                      </div>
                    </>
                    // <input value={'Нет инкасаций'} disabled={admin.role !== 'Аминистратор' && admin.role !== 'AllRights'} className={style.kassainput} placeholder="Инкасированно" />

                  )}
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                    {thisReport.status == "Открыт" && (admin.role == 'Аминистратор' || admin.role == 'AllRights') && (
                      <>
                        <input value={summIncasations} onChange={(e) => {

                          // if (summIncasations >= 0) {
                          setSummIncasations(e.target.value)

                          // }

                        }}

                          className={style.kassainput} placeholder="Сумма Инкосации" />

                        <Button text={incosationsReport.length == 0 ? 'Инкасировать' : 'Инкасировать ещё'} background='gold'
                          width='200px' height='30px'
                          onClick={() => {


                            save(thisReport)
                            Incasation()
                          }} />
                      </>
                    )}
                  </div>
                  <h2 style={{ color: 'white' }}>Остаток в кассе</h2>
                  <input value={Number(kassa.startweek) + Number(Number(revenues.z_otchet) - Number(revenues.beznal)) - on - summinc} className={style.kassainput} placeholder="Остаток в кассе" />

                </TabPanel>




              </Tabs >


            </div>


          </div>

        </div >
      )}
      {
        loading && (
          <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
        )
      }
      {
        addCARashod && (
          <AddContrAgentsRashodModal
            date={date}
            setThisReport={setThisReport}
            avansreport_id={selectedReport.id}
            shop_id={selectedReport.shop_id}></AddContrAgentsRashodModal>
        )
      }
      {/* {
        editCARashod && (
          <EditContrAgentsRashodModal
            thisNakladnaya={thisNakladnaya}
            setThisReport={setThisReport}
            avansreport_id={selectedReport.id}
            shop_id={selectedReport.shop_id}>

          </EditContrAgentsRashodModal>
        )
      } */}

    </>
  )
}
export default AvansReport;