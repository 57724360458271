import React from "react";
import style from "./Home.module.css";
import { Routes, Route, useParams, Navigate } from 'react-router-dom';
import { QtdContext } from "../../../context/qtdContext";
import Button from '../../../components/Button/Button'
import { useEffect, useState, useContext } from "react";
import LinksMenu from "../../../components/Header/LinksMenu";
import AddShopModal from "../../../components/Modals/AddShopModal";
import MakeOrderModal from "../../../components/Modals/MakeOrderModal";
import { FcInfo, FcRight, FcLeft, FcUndo, FcCancel, FcDataSheet, FcFullTrash } from "react-icons/fc";
import './react-confirm-alert.css'; // Import css
import { CiFloppyDisk } from "react-icons/ci";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { confirmAlert } from 'react-confirm-alert'; // Import
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import ScladOrderListModal from "../../../components/Modals/Sclad/ScladOrderListModal.js";
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import EditShopModal from "../../../components/Modals/EditShopModal.js";
// import SaleReport from "../../../components/Reports/SaleReport.js";
import RashoModalShop from "../../../components/Modals/Sclad/RashodModalShop.js";
import PrihodModalShop from "../../../components/Modals/Sclad/PrihodModalShop.js";
import SaleReportSteps from "../../../components/Modals/Reports/SaleReportSteps2.js";
import './react-confirm-alert.css'; // Import css
import ScladInventarisationModal from "../../../components/Modals/Sclad/ScladInventarisationModal.js";
import ScladInventarisationModalShop from "../../../components/Modals/Sclad/ScladInventarisationModalShop.js";
import ReplaseModalShop from "../../../components/Modals/Sclad/ReplaseModalShop.js";
import AvansReport from "../../../components/Modals/Reports/AvansReport.js";
import AvansReportCreate from "../../../components/Modals/Reports/AvansReportCreate.js";

const ShopPage = () => {
  const delay = (ms) => {
    return new Promise((r) => setTimeout(() => r(), ms));
  };
  // let { id } = useParams();
  // console.log(id.slice(1))
  const { shopKassas, setShopKassas, addCARashod, setaddCARashod, avansReports, setAvansReports, createavansReportModal, setCreateAvansReportModal, avansReportModal, setAvansReportModal, setAdmin, setSaleReportForms, setSaleReports, setCoffeeMashins, setTGorders, tgorders, replaseModalShop, setReplaseModalShop, checkAuth, saleReports, scladInventarisationModalShop, setScladInventarisationModalShop, Notify, saleReportForms, saleReportSteps, coffeeMashins, setSaleReportSteps, smenas, setSmenas, scladShops, rashodModalShop, setScladHistoryShops, prihodModalShop, setPrihodModalShop, setRashodModalShop, setScladShops, editShopModal, setEditShopModal, scladHistoryShops, setDashboarPage, admin, shops, setScladHistory, setScladOrders, setSclad, dashboarPage, scladOrders, admins, makeOrderModal, setMakeOrderModal, scladOrderListModal, setScladOrderListModal, sclad } = useContext(QtdContext);
  const [itemlist, setItemlist] = useState([])
  console.log(tgorders)
  const [status, setStatus] = useState([])
  const [loading, setLoading] = useState(false)
  const [orderId, setOrderId] = useState({})
  const [id, setId] = useState('')
  const [valueCalendar, onChangeCalendar] = useState(new Date());
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setpageCount] = useState(15);
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [selectedReport, setSelectedReport] = useState(null);
  const onChangeCalendar1 = async (options) => {
    // Selected options...
    onChangeCalendar(options)
    console.log("options...", options);
    setLoading(true)
    try {
      // // setSelectedValues(options);
      // console.log(new Date(options))
      // console.log(new Date(startDate))
      // const data = await $api.post(`${BACKURL}/api/salereports/getall/${1}`, {
      //   pageSize: 20, shops: selectedValues.map(pr => pr.value), startdate: new Date(options), enddate: new Date(options)
      // })
      // setCurrentPage(1);
      // setpageCount(20)
      // setAvansReports(data.data)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)

      const ScladOrders = async () => {
        if (admin.role == 'Бариста') {
          const data = await $api.get(`${BACKURL}/api/scladorder/getallbyshop/${dashboarPage.split(':')[1]}`)
          // console.log(data.data);
          setScladOrders(data.data)
        }

      }
      const ScladShopsHistory = async () => {
        if (admin.role == 'Бариста') {
          const data = await $api.get(`${BACKURL}/api/scladhistoryshop/getallbyshop/${dashboarPage.split(':')[1]}`)
          // console.log(data.data);
          setScladHistoryShops(data.data)
        }

      }
      const ScladShops = async () => {
        if (admin.role == 'Бариста') {
          const data = await $api.get(`${BACKURL}/api/scladshops/getallbysgop/${dashboarPage.split(':')[1]}`)
          // console.log(data);
          setScladShops(data.data)
        }

      }

      const SaleReports = async () => {
        if (admin.role == 'Бариста') {
          const data = await $api.get(`${BACKURL}/api/salereports/getallbysgop/${dashboarPage.split(':')[1]}`)
          // console.log(data.data);
          setSaleReports(data.data)
        }

      }
      const CoffeeMashins = async () => {

        if (admin.role == 'Бариста') {
          const data = await $api.get(`${BACKURL}/api/coffeemashins/getallbyshop/${dashboarPage.split(':')[1]}`)
          // console.log(data.data);
          setCoffeeMashins(data.data)
        }

      }
      const Smena = async () => {
        if (admin.role == 'Бариста') {
          const data = await $api.get(`${BACKURL}/api/smena/getallbyid/${admin.id}`)
          // console.log(data.data);
          setSmenas(data.data)
        }

      }
      const SaleReportsForms = async () => {
        if (admin.role == 'Бариста') {
          const data = await $api.get(`${BACKURL}/api/salereports/getallformsbyshop/${dashboarPage.split(':')[1]}`)
          setSaleReportForms(data.data)
        }

      }
      const AvansReports = async () => {
        // setLoading(true)
        if (admin.role != 'Кладовщик') {
          const data = await $api.post(`${BACKURL}/api/avansreports/getallshop/${dashboarPage.split(':')[1]}`, { pageSize: pageSize, page: page })
          // console.log(data.data);
          setAvansReports(data.data)
        }
        // setLoading(false)


      }
      const promiseall3 = await Promise.all([
        AvansReports(), Smena(), SaleReportsForms(), ScladOrders(), SaleReports(), ScladShopsHistory(), ScladShops(), CoffeeMashins()]

      )
      setLoading(false)
    }
    fetchData()
  }, [])
  useEffect(() => { 
    const AvansReports2 = async () => {
      setLoading(true)
      if (admin.role != 'Кладовщик') {
        const data = await $api.post(`${BACKURL}/api/avansreports/getallshop/${dashboarPage.split(':')[1]}`, { pageSize: pageSize, page: page })
        // console.log(data.data);
        setAvansReports(data.data)
      }
      setLoading(false)


    }
    AvansReports2()
   }, [page, pageSize])
  if (!(admin.role == 'AllRights' || admin.role == 'Администратор' || admin.role == 'Бариста')) {
    setDashboarPage('Home')
    return <Navigate to="/" replace />;
  } else {
    const smena = smenas.find(pr => pr.shop_id == (dashboarPage.split(':')[1]) && pr.open)

    const saleReport = () => {
      setSaleReportSteps(true)
    }
    const avansReport = (report) => {
      setSelectedReport(report)
      setAvansReportModal(true)
    }
    const avansReportnew = () => {
      setCreateAvansReportModal(true)
    }
    const addRashod = () => {
      setRashodModalShop(true)
    }
    const Replase = () => {
      setReplaseModalShop(true)
    }
    const addPrihod = () => {
      setPrihodModalShop(true)
    }
    const shopssclad = scladShops.filter(pr => pr.shop_id === Number(dashboarPage.split(':')[1]))
    const sorted = shopssclad.sort((a, b) => a.name > b.name ? 1 : -1);
    const scladHistory = scladHistoryShops.filter(pr => pr.shop_id == Number(dashboarPage.split(':')[1]))
    // сщтыщдуюдщп()
    const sortedHistory = scladHistory.sort((a, b) => a.id < b.id ? 1 : -1);

    const uniqueSerialsSclad = Array.from(new Set(shopssclad.map(item => item.kategory)));

    const OpnaEditModal = (id) => {
      setId(id)
      setEditShopModal(id)
    }
    const showList = (listik, statusik, id) => {
      setOrderId(id)
      setItemlist(listik)
      setStatus(statusik)
      setScladOrderListModal(true)
    }
    // console.log(dashboarPage.split(':')[1])
    const shop = shops.find(shop => shop.id == dashboarPage.split(':')[1]);
    const shopOrders = scladOrders.filter(pr => pr.shop_id == dashboarPage.split(':')[1])
    // console.log(shopOrders)

    // const [thisshop, ==]
    const uniqueSerials = ['Передан на склад!', 'Собирается!', 'В доставке!', 'Выполнен!']
    // console.log(uniqueSerials)
    const addOrder = () => {
      setMakeOrderModal(true)
    }
    const updateStatus = async (id, serials) => {
      let newStatus = ''
      switch (true) {
        case (serials == 'Передан на склад!'):
          newStatus = 'Собирается!'
          break
        case (serials == 'Собирается!'):
          newStatus = 'В доставке!'
          break
        case (serials == 'В доставке!'):
          newStatus = 'Выполнен!'
          break
      }
      // console.log(id, serials, newStatus)

      try {
        setLoading(true)
        // console.log(newValue)
        const refresh = await checkAuth()
        if (refresh) {
          const newProd = await $api.post(`${BACKURL}/api/scladorder/updatestatus`,
            { id: id, newStatus: newStatus, admin: admin.id }
          )
          setScladHistory(newProd.data.scladHistory)
          setScladHistoryShops(newProd.data.scladHistoryShops)
          setSmenas(newProd.data.smenas)
          setSclad(newProd.data.sclad)
          setScladOrders(newProd.data.scladOrder)
          setScladShops(newProd.data.scladShopes)

          // //Проверь правильность данных тут
          // setServerProd(newPosts)
          // console.log(newProd.data)
          // setModalInfo(false)
          Notify.addNotification({
            title: "Готово!",
            message: "Заказ успешно принят!",
            type: "success",
            insert: "bottom",
            container: "bottom-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
        }
        setLoading(false)

      } catch (e) {
        Notify.addNotification({
          title: "Ошибка!",
          message: "Произошла непредвиденная ошибка!",
          type: "danger",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        })
      }
    }


    const startInv = async () => {
      setScladInventarisationModalShop(true)
    }
    const deleteIt = async (id) => {
      try {
        setLoading(true)
        const refresh = await checkAuth()
        if (refresh) {
          const deleteProd = await $api.post(`${BACKURL}/api/scladshops/delete/${id}`, {
            smena_id: smena.id, admin_id: admin.id
          })
          setSmenas(deleteProd.data.smenas)
          setScladShops(scladShops.filter(item => item.id != deleteProd.data.sclad.id))
          Notify.addNotification({
            title: "Готово!",
            message: "Продукт со склада точки удален!",
            type: "success",
            insert: "bottom",
            container: "bottom-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
        }
        setLoading(false)

      } catch (e) {
        console.log(e)
        Notify.addNotification({
          title: "Ошибка!",
          message: "Произошла непредвиденная ошибка!",
          type: "danger",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        })
      }
    }
    const submit = async (func, id) => {
      confirmAlert({
        title: 'Удалить товар',
        message: 'Вы уверены, что хотите удалить товар со склада?',
        buttons: [
          {
            label: 'Да!',
            onClick: async () => await func(id)
          },
          {
            label: 'Отмена!',
            //   onClick: () => alert('Click No')
          }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
      });
    };

    const OpenSmena = async () => {
      try {
        setLoading(true)
        const refresh = await checkAuth()
        if (refresh) {
          const open = await $api.post(`${BACKURL}/api/smena/open`, {
            shop_id: Number(dashboarPage.split(':')[1]),
            admin_id: admin.id,
          })
          setSmenas([...smenas, open.data.smena])
          setAvansReports([...avansReports, open.data.avansreport])
          setScladShops(open.data.scladshops)
          setCoffeeMashins(open.data.cm)
          Notify.addNotification({
            title: "Готово!",
            message: "Смена успешно открыта!",
            type: "success",
            insert: "bottom",
            container: "bottom-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
        }
        setLoading(false)

      } catch (e) {
        console.log(e)
        Notify.addNotification({
          title: "Ошибка!",
          message: "Произошла непредвиденная ошибка!",
          type: "danger",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        })
      }
    }
    const submitOpan = async (func) => {
      confirmAlert({
        title: `Открыть смену - ${shop.name}`,
        message: 'Вы уверены, что хотите открыть смену? После открытия - вы не сможете закрыть смену пока не сдадите все отчеты!',
        buttons: [
          {
            label: 'Да!',
            onClick: async () => await OpenSmena()
          },
          {
            label: 'Отмена!',
            //   onClick: () => alert('Click No')
          }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
      });
    };
    const CloseSmena = async (id) => {
      try {
        setLoading(true)
        const refresh = await checkAuth()
        if (refresh) {
          const open = await $api.get(`${BACKURL}/api/smena/close/${id}`)
          setSmenas(open.data.smena)
          setCoffeeMashins(open.data.cm)
          setScladShops(open.data.scladshops)
          setAvansReports(avansReports.map(d => {
            if (d.id == open.data.avansreport.id) {
              return open.data.avansreport
            } else {
              return d
            }
          }))
          setShopKassas(shopKassas.map(d => {
            if (d.id == open.data.kassa.id) {
              return open.data.kassa
            } else {
              return d
            }
          }))
          // await delay(2000)
          // setAdmin({ auth: false, user: '' });
          // console.log(smenas)
          Notify.addNotification({
            title: "Готово!",
            message: "Смена успешно закрыта!",
            type: "success",
            insert: "bottom",
            container: "bottom-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
        }
        setLoading(false)

      } catch (e) {
        console.log(e)
        Notify.addNotification({
          title: "Ошибка!",
          message: "Произошла непредвиденная ошибка!",
          type: "danger",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        })
      }
    }
    const CloseSmenaReturn = async () => {
      await CloseSmena(smena.id)
      localStorage.removeItem('token');
      setAdmin({ auth: false, user: '' });

    }
    const submitClose = async (id) => {
      confirmAlert({
        title: `Закрыть смену - ${shop.name}`,
        message: 'Вы уверены, что хотите закрыть смену? Отчеты изменить будет невозможно!',
        buttons: [
          {
            label: 'Да!',
            onClick: async () => await CloseSmena(id)
          },
          {
            label: 'Отмена!',
            //   onClick: () => alert('Click No')
          }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
      });
    };
    const cm = coffeeMashins.find(pr => pr.shop_id == shop.id)
    const srform = saleReportForms.find(forms => (forms.id == shop.slform_id && forms.showed))
    const saved = saleReports.find(pr => (pr.shop_id == shop.id && pr.status == 'Сохранен'))
    // function testFinished() {
    // const smena = smenas.find(pr => pr.shop_id == (dashboarPage.split(':')[1]) && pr.open)

    let endedReport = false
    //   if (endedReport) {
    //     return true
    //   } else {
    //     return false
    //   }
    // }
    let myorder1
    let myorder
    if (smena) {
      myorder1 = tgorders.filter(pr => pr.shop_id == smena.shop_id)
      myorder = myorder1.sort((a, b) => a.id < b.id ? 1 : -1)
      endedReport = saleReports.find(pr => (pr.smena_id == smena.id && pr.status == 'Завершен'))
    }
    const comfirmTG = async (tg_id) => {
      try {
        setLoading(true)
        // consolw.log(tg_id)
        const open = await $api.get(`${BACKURL}/api/tgorders/comfirm/${tg_id}`)
        setTGorders(open.data)
        setLoading(false)
      } catch (e) {
        console.log(e)
        setLoading(false)

      }
    }
    const shopar = avansReports.filter(pr => pr.shop_id == shop.id).sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.createdAt) - new Date(a.createdAt);
    })
    console.log('avansReports', avansReports)
    return (<>
      {smena && (
        <div className={style.sclad_container}>
          <h1 >{shop.name}</h1>
          {!saved && (<>
            <Button text='Закрыть Смену' background='red' onClick={() => submitClose(smena.id)} />
          </>
          )}
          <div style={{ width: '100%' }}>


            <Tabs >
              <TabList>
                <Tab key={"Заказы"}> Заказы</Tab>
                <Tab key={"Telegram Заказы"}> Telegram Заказы</Tab>
                <Tab key={"Склад"}> Склад</Tab>
                <Tab key={"Отчеты"}> Отчеты</Tab>
              </TabList>
              <TabPanel>
                <h3 >Заявки</h3>
                <div>
                  <Button text='Заказать товар' background='orange' onClick={addOrder} />
                  {(admin.role == 'AllRights' || admin.role == 'Администратор') && (
                    <Button text='Изменить точку' background='orange' onClick={() => OpnaEditModal(shop.id)} />

                  )}
                </div>


                {shopOrders.length > 0 && (<>
                  <Tabs forceRenderTabPanel>
                    <TabList>
                      {uniqueSerials.map(serials => {
                        const one = shopOrders.filter(pr => pr.status == serials && pr.status != 'Выполнен!')

                        return <Tab key={serials}> {`${serials}   `}
                          {one.length > 0 && (
                            <span style={{ 'marginLeft': '15px' }} className={style.badge}>{one.length}</span>

                          )}

                        </Tab>
                      }
                      )}


                    </TabList>
                    {uniqueSerials.map(serials => {
                      // console.log(serials, shopOrders)
                      const table2 = shopOrders.filter(prod => prod.status === serials)
                      const table = table2.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))

                      return (<>
                        <TabPanel>
                          <div key={`${serials}`} className={style.table2}>
                            <table className={style.table}>
                              <thead className={style.tableth}>

                                <tr>
                                  <td className={style.tabletd}>№</td>
                                  <td className={style.tabletd}>Сотрудник</td>
                                  <td className={style.tabletd}>Список</td>
                                  <td className={style.tabletd}>Дата</td>
                                  {serials == 'В доставке!' && (
                                    <td className={style.tabletd}>Действия</td>

                                  )}

                                </tr>
                              </thead>
                              {table.map(prod2 => {
                                const user = admins.find(pr => pr.id == prod2.admin_id)
                                // console.log(user)
                                const date3 = new Date(prod2.updatedAt);
                                const date4 = date3.getTime()
                                const date = new Date(date4 + 2 * 60 * 60 * 1000)
                                // Форматирование времени в формате HH:mm
                                const time = ("0" + date.getUTCHours()).slice(-2) + ":" + ("0" + date.getUTCMinutes()).slice(-2);

                                // Форматирование даты в формате YYYY-MM-DD
                                const formattedDate = date.toISOString().slice(0, 10);

                                // Сбор итоговой строки
                                const result = time + " " + formattedDate;
                                return (<tbody>

                                  <tr>
                                    <td className={style.tabletd}>{prod2.id}</td>
                                    <td className={style.tabletd}>{user.fullname}</td>
                                    <td className={style.tabletd}>
                                      <div className={style.cell}>
                                        <FcInfo
                                          onClick={() => showList(prod2.list, prod2.status, prod2.id)}
                                        />
                                      </div>
                                    </td>
                                    <td className={style.tabletd}><b>{result}</b></td>
                                    {serials == 'В доставке!' && (
                                      <td className={style.tabletd}>
                                        <div className={style.cell}>

                                          <Button text='Принять' background='orange'
                                            onClick={() => updateStatus(prod2.id, serials)}
                                          />
                                        </div>
                                      </td>
                                    )}


                                  </tr>



                                </tbody>)
                              }

                              )}
                            </table>
                          </div>
                        </TabPanel>

                      </>)
                    })}
                  </Tabs>
                </>
                )}
              </TabPanel>
              <TabPanel>

                <div className={style.tgcontainer}>
                  {/* {tgorders.length>0&&(<> */}
                  {myorder.map(pr => {

                    const order = pr.order.map(pr1 => JSON.parse(pr1))
                    // const orderShop = order.filter(pr=>pr.shop_id==smena.shop_id)
                    return (<div className={style.tgitem}>
                      <h5 >Заказ №{pr.id}</h5>
                      <h6>{pr.status}</h6>
                      <ol>
                        {order.map((tt, index) => {
                          return <li className={style.lili}>
                            <b>{`${index + 1})`}</b>{tt.lastSelectedItem} x <b>{tt.quantity}шт</b>
                          </li>
                        })}
                      </ol>
                      Итого:  <b>{pr.amaunt}руб</b>
                      {pr.status != 'Подтвержден' && (
                        <Button text="ПОДТВЕРДИТЬ" onClick={() => comfirmTG(pr.id)}></Button>

                      )}
                      {/* {pr.id} - {pr.status}  */}
                    </div>)
                  })}
                  {/* {/* </>   )} */}
                </div>
              </TabPanel>
              <TabPanel>
                {/* {admin.role == 'AllRights' && ( */}

                <>
                  <h3 >Склад {shop.name}</h3>
                  <div>
                    <Button text='Оприходывать' background='#89f08c'
                      onClick={addPrihod}
                    />
                    <Button text='Списать' background='#ffc6c6'
                      onClick={addRashod}
                    />
                    {/* <Button text='Перместить' background='#C3FFFC'
                    onClick={Replase} */}
                    {/* /> */}
                    {!saved && (
                      <Button text='Инвентаризация' background='#d9d9ff'
                        onClick={startInv}
                      />
                    )}

                  </div>
                  <Tabs forceRenderTabPanel>
                    <TabList>
                      {uniqueSerialsSclad.map(serials => <Tab key={serials}> {serials}</Tab>)}
                      {sortedHistory.length > 0 && (
                        <Tab key={"История"}> История</Tab>
                      )}
                    </TabList>

                    {uniqueSerialsSclad.map(serials => {

                      const table = sorted.filter(prod => prod.kategory === serials)
                      return (<>
                        <TabPanel>
                          <div key={`${serials}`} className={style.table2}>
                            <table className={style.table}>
                              <thead className={style.tableth}>

                                <tr>
                                  <td className={style.tabletd}>№</td>
                                  <td className={style.tabletd}>Наименоване</td>
                                  {/* <td className={style.tabletd}>Категория</td>
                                <td className={style.tabletd}>Кратность</td>
                                <td className={style.tabletd}>Макс</td> */}
                                  <td className={style.tabletd}>Остаток</td>
                                  {/* <td className={style.tabletd}>Бронь</td> */}
                                  {/* <td className={style.tabletd}>on/off</td> */}
                                  {admin.role == 'AllRights' && (
                                    <td className={style.tabletd}>Действия</td>

                                  )}

                                </tr>
                              </thead>
                              {table.map(prod2 =>

                                <tbody>

                                  <tr>
                                    <td className={style.tabletd}>{prod2.id}</td>
                                    <td className={style.tabletd}>{prod2.name}</td>
                                    {/* <td className={style.tabletd}>{prod2.kategory}</td> */}
                                    {/* <td className={style.tabletd}>{prod2.step}{prod2.mnt}</td> */}
                                    {/* <td className={style.tabletd}>{prod2.qty * prod2.step}{prod2.mnt}</td> */}
                                    <td className={style.tabletd}><b>{prod2.scldqty}{prod2.mnt}</b></td>
                                    {/* <td className={style.tabletd}><b>{prod2.scldbron}{prod2.mnt}</b></td> */}
                                    {/* <td className={style.tabletd}>
                  <div>
                    <ToggleCheckbox checked={prod2.showed} onChange={() => toggle(prod2.id)} />
                  </div>
                </td> */}

                                    {admin.role == 'AllRights' && (
                                      <td className={style.tabletd}>

                                        <div className={style.cell}>
                                          <FcCancel onClick={() => submit(deleteIt, prod2.id)} />
                                        </div>
                                      </td>
                                    )}


                                  </tr>



                                </tbody>)}
                            </table>
                          </div>
                        </TabPanel>

                      </>)
                    })}
                    {sortedHistory.length > 0 && (
                      <TabPanel>
                        <div key={`История`} className={style.table2}>
                          <table className={style.table}>
                            <thead className={style.tableth}>

                              <tr>
                                <td className={style.tabletd}></td>
                                <td className={style.tabletd}>Дата</td>
                                <td className={style.tabletd}>Пользователь</td>
                                <td className={style.tabletd}>Тип</td>
                                <td className={style.tabletd}>Категория</td>
                                <td className={style.tabletd}>Список</td>
                                <td className={style.tabletd}>Примечание</td>
                                {/* {prod2.type == 'Инвентаризация' && ( */}
                                {/* <td className={style.tabletd}>Накладная</td> */}

                                {/* )} */}
                              </tr>
                            </thead>
                            {sortedHistory.map(prod2 => {
                              // const item = 
                              const date3 = new Date(prod2.createdAt);
                              const date4 = date3.getTime()
                              const date2 = new Date(date4 + 2 * 60 * 60 * 1000)
                              // Форматирование времени в формате HH:mm
                              const time = ("0" + date2.getUTCHours()).slice(-2) + ":" + ("0" + date2.getUTCMinutes()).slice(-2);

                              // Форматирование даты в формате YYYY-MM-DD
                              const formattedDate = date2.toISOString().slice(0, 10);
                              const adm = admins.find(pr => pr.id == prod2.admin_id)
                              // Сбор итоговой строки
                              const result = time + " " + formattedDate;
                              return (<tbody>

                                <tr>

                                  <td className={style.tabletd}>
                                    {prod2.type === 'Инвентаризация' && (<div className={style.cell}><>
                                      <FcDataSheet className={style.icon} />
                                    </> </div>)}
                                    {prod2.type === 'Приход' && (<div className={style.cell}><>
                                      <FcLeft className={style.icon} />
                                    </> </div>)}
                                    {prod2.type === 'Доставка' && (<div className={style.cell}><>
                                      <FcRight className={style.icon} />
                                    </> </div>)}
                                    {prod2.type === 'Расход' && prod2.kategory == 'Возврат' && (<div className={style.cell}><>
                                      <FcUndo className={style.icon} />
                                    </> </div>)}
                                    {prod2.type === 'Расход' && prod2.kategory != 'Возврат' && prod2.kategory != 'Списание' && (<div className={style.cell}><>
                                      <FcRight className={style.icon} />
                                    </> </div>)}

                                    {prod2.type === 'Расход' && prod2.kategory === 'Списание' && (<div className={style.cell}><>
                                      <FcFullTrash className={style.icon} />
                                    </> </div>)}
                                  </td>
                                  <td className={style.tabletd}>{result}</td>
                                  <td className={style.tabletd}>{adm.fullname}</td>
                                  <td className={style.tabletd}>{prod2.type}</td>
                                  <td className={style.tabletd}>{prod2.kategory}</td>
                                  <td className={style.tabletd}>
                                    <FcInfo onClick={() => { showList(prod2.list, prod2.type) }} className={style.icon} />
                                  </td>
                                  <td className={style.tabletd}>{prod2.comment}</td>
                                  {/* {prod2.type == 'Инвентаризация' && (
                                  <td className={style.tabletd}>
                                    <td className={style.tabletd}>
                                      <div className={style.cell}>
                                        <CiFloppyDisk
                                        // onClick={() => downloadInvent(prod2.id)}
                                        />
                                      </div>
                                    </td>
                                  </td>

                                )} */}


                                </tr>



                              </tbody>)

                            }
                            )}
                          </table>
                        </div>
                      </TabPanel>)}
                  </Tabs >
                </>
                {/* // )} */}
              </TabPanel>
              <TabPanel>
                <Tabs forceRenderTabPanel>
                  <TabList>
                    <Tab key={"Отчеты о продажах"}> Отчеты о продажах</Tab>
                    {shop.name == "Портовая" && (
                      <Tab key={"Авансовые отчеты"}> Авансовые отчеты</Tab>
                    )}
                  </TabList>
                  <TabPanel>
                    {cm && srform && !endedReport && (<>
                      <Button text='Статистика продаж' height='150px' width='150px' background='gold' onClick={() => saleReport()} />
                    </>
                    )}
                    {endedReport && (
                      <Button text='Отчет отправлен' height='150px' width='150px' background='rgb(137, 240, 140)' />

                    )}
                  </TabPanel>
                  {shop.name == "Портовая" && (
                    <TabPanel>
                      {/* <Button text='Создать Новый' width='150px' background='gold' onClick={() => avansReportnew()} /> */}

                      {/* <Button text='Авансовый отчет' height='150px' width='150px' background='gold' onClick={() => avansReport()} /> */}
                      <div style={{ flexDirection:'row',width: '100%', display: "flex", justifyContent: "center", alignItems: 'center' }}>
                      <div style={{ flexDirection:'column',width: '100%', display: "flex", justifyContent: "center", alignItems: 'center' }}>
                        <label >Страница</label>

                        <select name="pets" id="prod"
                          onChange={async (event) => {
                            setPage(event.target.value)
                            // setLoading(true)
                            // await avansReports()
                            // setLoading(false)

                          }}
                        >
                          <option value={page}>{page}</option>

                          <option value={1}>{1}</option>
                          <option value={2}>{2}</option>
                          <option value={3}>{3}</option>
                          <option value={4}>{4}</option>
                          <option value={5}>{5}</option>




                        </select>
</div>
                      <div style={{ flexDirection:'column',width: '100%', display: "flex", justifyContent: "center", alignItems: 'center' }}>
                        <label >Разрядность</label>

                        <select name="pets" id="prod"
                          onChange={async (event) => {
                            setPageSize(event.target.value)
                            // setLoading(true)
                            // await avansReports()
                            // setLoading(false)

                          }}
                        >
                          <option value={pageSize}>{pageSize}</option>

                          <option value={5}>{5}</option>
                          <option value={10}>{10}</option>
                          <option value={15}>{15}</option>
                          <option value={30}>{30}</option>




                        </select>
</div>


                      </div>
                      <div style={{ width: '100%' }}>

                        {shopar.map(prod2 => {
                          function formatDate(dateString) {
                            const date = new Date(dateString);
                            const months = [
                              'янв', 'фев', 'мар', 'апр', 'май', 'июн',
                              'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'
                            ];
                            const day = date.getDate();
                            const month = months[date.getMonth()];
                            const year = date.getFullYear();

                            return `${day} ${month} ${year}`;
                          }
                          let rep = []
                          let titel = ''
                          // console.log(prod2.reports)
                          // if (prod2.reports.length > 0) {

                          //   // rep= prod2.reports.map(pr => {if (typeof pr == 'string'){return JSON.parse(pr)}else {return pr}})
                          //   console.log(rep, prod2.reports)
                          titel = 'teast'
                          // `${formatDate(rep[0].date)} - ${formatDate(rep[rep.length - 1].date)}`
                          // }
                          // const dd = shops.find(pr => pr.id == prod2.shop_id)
                          // const smena = smenas.find(pr => pr.id == prod2.smena_id)
                          // const user = admins.find(pr => pr.id == prod2.admin_id)
                          const date = new Date(prod2.createdAt);
                          const formattedDate = `${date.getDate()}/${date.getMonth() + 1}`
                          return (
                            // <tbody>
                            <>
                              <div style={{ borderBottom: "2px solid rgba(0,0,0,.473)", width: '100%', display: "flex", textAlign: "center", flexDirection: 'row', justifyContent: "left", alignItems: 'center', color: "rgba(0,0,0,.473)" }}>
                                <div style={{ width: '40%' }}>
                                  {formattedDate} </div>
                                <div style={{ width: '20%' }}>
                                  <Button text='Отчет' width='50px' background={prod2.status=='Открыт'?'orange':"grey"}
                                    onClick={() => avansReport(prod2)}
                                  />
                                </div>
                                <div style={{ width: '40%' }}>
                                  {prod2.status}
                                </div>
                              </div>
                            </>

                          )
                        }

                        )}
                      </div>
                    </TabPanel>
                  )}

                </Tabs >



              </TabPanel>
            </Tabs >
          </div>
        </div>
      )}
      {!smena && (
        <div className={style.sclad_container}>
          <h1>Открыть смену - {new Date().toLocaleDateString()} {shop.name}</h1>
          <Button text='Открыть' background='#fba0ff' onClick={submitOpan} />
        </div>
      )}
      {scladOrderListModal && (
        <ScladOrderListModal
          scladOrderdata={itemlist}
          status={status}
          order_id={orderId}

        />

      )}
      {saleReportSteps && (
        <SaleReportSteps
          CloseSmenaReturn={CloseSmenaReturn}
          shop={shop}
          smena={smena}


        />

      )}
      {avansReportModal && (
        <AvansReport
          CloseSmenaReturn={CloseSmenaReturn}
          shop={shop}
          smena={smena}
          selectedReport={selectedReport}

        />

      )}
      {scladInventarisationModalShop && (
        <ScladInventarisationModalShop
          id={shop.id}


        />

      )}
      {editShopModal && (
        <EditShopModal
          id={id}

          shop_id={shop.id}
        />

      )}
      {makeOrderModal && (
        <MakeOrderModal
          shop_id={shop.id}
        />

      )}
      {createavansReportModal && (
        <AvansReportCreate shop_id={shop.id} />
      )}
      {rashodModalShop && (
        <RashoModalShop
          shop_id={shop.id}
        />

      )}
      {replaseModalShop && (
        <ReplaseModalShop
          shop_id={shop.id}
        />

      )}
      {prihodModalShop && (
        <PrihodModalShop
          // smena = {smena}
          shop_id={shop.id}
        />

      )}
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>

      )}


      {/* <div className={style.sclad_container}>
                <h1 >{shop.name}</h1>





                <div className={style.container}>
                    <div className={style.sidecontainer}>
                        <div className={style.title}>
                            <h2 >Создать заказ</h2>
                        </div>
                        <div className={style.order_container}>
                            <div>
                                Форма
                            </div>
                            <div>
                                Таблица
                            </div>
                        </div>
                    </div>
                    <div className={style.sidecontainer}>
                        <div className={style.title}>
                            <h2 >Заказы</h2>
                        </div>
                        <div className={style.order_container}>
                            <div>
                                Форма
                            </div>
                        </div>
                    </div>

                </div>


            </div> */}





    </>
    );
  }

};

export default ShopPage;
