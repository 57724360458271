import React from "react";
import style from "./Home.module.css";
import { Routes, Route, Link, Navigate } from 'react-router-dom';
import { QtdContext } from "../../../context/qtdContext";
import Button from '../../../components/Button/Button'
import 'react-tabs/style/react-tabs.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { FcCancel, FcEngineering } from "react-icons/fc";
import $api from '../../../http/index.ts'
import './react-confirm-alert.css'; // Import css

import { useEffect, useState, useContext } from "react";
import LinksMenu from "../../../components/Header/LinksMenu";
// import AddProductModal from "../../../components/Modals/AddProductModal";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import AddEmployeesModal from "../../../components/Modals/Emloyees/AddEmployeesModal.js";
import EditEmployeesModal from "../../../components/Modals/Emloyees/EditEmployeesModal.js";
import AddContrAgentsModal from "../../../components/Modals/ContrAgents/AddContrAgentsModal.js";

const ShopIncasations = () => {
  const { incosations, setIncosations, shopKassas, contragents, Notify, editEmployeesModal, addContrAgentsModal, setAddContrAgentsModal, setEditEmployeesModal, admin, admins, setAdmins, addEmployeesModal, setAddEmployeesModal, setDashboarPage, shops } = useContext(QtdContext);
  const [loading, seloading] = useState(false)
  const [id, setId] = useState('')

  if (!(admin.role == 'AllRights' || admin.role == 'Администратор')) {
    setDashboarPage('Home')
    // setAdmin({ auth: false});
    return <Navigate to="/" replace />

  } else {

    // console.log(admin.role != 'GOD' && admin.role != 'Администратор')
    // useEffect(()=>{})
    const users = admins.filter(pr => pr.role != 'AllRights')
    const sorted = users.sort((a, b) => a.fullname > b.fullname ? 1 : -1);
    const addProduct = () => {
      setAddContrAgentsModal(true)
    }

    // useEffect(())

    const OpnaEditModal = (id) => {
      setId(id)
      setEditEmployeesModal(id)
    }
    const deleteIt = async (id) => {
      try {
        seloading(true)

        const deleteProd = await $api.get(`${BACKURL}/api/admins/delete/${id}`)
        setAdmins(admins.filter(item => item.id != id))
        seloading(false)

        Notify.addNotification({
          title: "Готово!",
          message: "Сотрудник успешно удален из системы!",
          type: "success",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      } catch (e) {
        console.log(e)
        seloading(false)

        Notify.addNotification({
          title: "Ошибка!",
          message: "Произошла непредвиденная ошибка!",
          type: "danger",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        })
      }
    }

    const submit = async (func, id) => {
      confirmAlert({
        title: 'Удалить сотрудника',
        message: 'Вы уверены, что хотите удалить сотрудника из системы?',
        buttons: [
          {
            label: 'Да!',
            onClick: async () => await func(id)
          },
          {
            label: 'Отмена!',
            //   onClick: () => alert('Click No')
          }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
      });
    };
    return (<>

      <div className={style.sclad_container}>
        {/* <Button text='Создать Контрагента' background='orange' onClick={addProduct} /> */}

        <div className={style.table2}>
          <table className={style.table}>
            <thead className={style.tableth}>

              <tr>
                <td className={style.tabletd}>№</td>
                <td className={style.tabletd}>Точка</td>
                <td className={style.tabletd}>Инкосатор</td>
                <td className={style.tabletd}>Сотрудник</td>
                <td className={style.tabletd}>Сумма</td>
                <td className={style.tabletd}>Подтверждение</td>
                {/* <td className={style.tabletd}>inn</td>
                <td className={style.tabletd}>Договор</td>
                <td className={style.tabletd}>Банк</td> */}

                <td className={style.tabletd}>Действия</td>
              </tr>
            </thead>
            <tbody>
              {incosations.map(employee => {
                const sh = shops.find(gg => gg.id == employee.shop_id)
                const admin = admins.find(gg => gg.id == employee.admin_id)
                const admin2 = admins.find(gg => gg.id == employee.employee_id)

                return (<tr>
                  <td className={style.tabletd}>{employee.id}</td>
                  <td className={style.tabletd}>{sh.name}</td>
                  <td className={style.tabletd}>{admin.fullname}</td>
                  <td className={style.tabletd}>{admin2 ? admin2.fullname : 'Ожидаем подтверждение'}</td>
                  <td className={style.tabletd}>{employee.money}руб</td>
                  <td className={style.tabletd}>{employee.confirm ? 'Да' : 'Нет'}</td>

                  {/* <td className={style.tabletd}>{employee.inn}</td>
                  <td className={style.tabletd}>{employee.dogovor_number} от {employee.dogovor_date}</td>
                  <td className={style.tabletd}>{employee.bank_name}</td>
                   */}
                  <td className={style.tabletd} style={{
                    'display': 'flex',
                    'justifyContent': 'space-around'
                  }}>
                    <div className={style.cell}>
                      <FcEngineering
                      // onClick={() =>
                      // OpnaEditModal(employee.id)
                      // }
                      />
                    </div>
                    <div className={style.cell}>

                      <FcCancel
                      // onClick={() => submit(deleteIt, employee.id)}
                      />
                    </div>
                  </td>
                </tr>)
              }
              )}



            </tbody>
          </table>
        </div>

      </div>

      {editEmployeesModal && (
        <EditEmployeesModal
          id={id}

        />

      )}
      {addContrAgentsModal && (
        <AddContrAgentsModal

        />

      )}

      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}


    </>
    );
  }


};

export default ShopIncasations;
