import { React, useState, useContext } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import { QtdContext } from "../../context/qtdContext";
import Button from '../../components/Button/Button.js'
import $api from '../../http/index.ts'
import { BACKURL } from '../../conf.js'
import Loading from 'react-fullscreen-loading';
import './Sidebar.css'
import { MdCoffeeMaker } from "react-icons/md";
import { CiWavePulse1, CiShop, CiCoffeeCup, CiViewList, CiBoxes, CiDollar, CiUser, CiSettings, CiEdit, CiViewColumn, CiBag1,CiMemoPad } from "react-icons/ci";

function LinksMenu() {

  const [loading, setLoading] = useState(false)

  const [isOpen, setIsopen] = useState(false);
  const { checkAuth, Notify, admin, setDashboarPage, dashboarPage, setAdmin, scladOrders } = useContext(QtdContext);
  const Opan = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  }
  const ToggleSidebar = (page) => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
    localStorage.setItem('dashboardpage', page)
    setDashboarPage(page)
  }
  const LogOut = async () => {
    setLoading(true)
    const refresh = await checkAuth()
    if (refresh) {
      await $api.post(`${BACKURL}/api/utbteam/logout`)
      setAdmin({ auth: false, user: '' });

      Notify.addNotification({
        title: "Готово!",
        message: "Вы успешно вышли из системы!!",
        type: "success",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }

    setLoading(false)

    return
  }
  const one = scladOrders.filter(pr => pr.status != 'Выполнен!')

  return (
    <>
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
      {!loading && (<>

        {admin.auth && (
          <div className="container-fluid mt-3">

            <nav className="navbar navbar-expand-lg navbar-light bg-white shadow-md" >
              <div className="container-fluid p-2">
                <a className="navbar-brand text-primary mr-0">CityCoffee</a>
                <div className="form-inline ml-auto">
                  <div className="btn btn-primary" onClick={Opan} >
                    <i className="fa fa-bars"></i>Меню
                  </div>
                </div>
              </div>
            </nav>
            <div className={`sidebar ${isOpen == true ? 'active' : ''}`}>
              <div className="sd-header">
                <h4 className="mb-0">Меню</h4>
                <div className="btn btn-primary" onClick={() => ToggleSidebar(dashboarPage)}><i className="fa fa-times"></i>Закрыть</div>
              </div>
              <div className="sd-body">
                <ul>
                  <li>
                    <NavLink className='sd-link' onClick={() => ToggleSidebar('Home')} >
                      <div className='cell'>
                        <CiSettings style={{ 'marginRight': '15px' }} /> Аккаунт
                      </div>
                    </NavLink>
                  </li>
                  {(admin.role == 'Кладовщик' || admin.role == 'AllRights' || admin.role == 'Администратор' || admin.role == 'Бариста') && (

                    <li>
                      <NavLink className='sd-link' onClick={() => ToggleSidebar('Shops')} >
                        <div className='cell'>
                          <CiShop style={{ 'marginRight': '15px' }} /> Точки
                        </div>

                      </NavLink>
                    </li>
                  )}
                  {admin.role != 'Бариста' && (<>
                    <li>
                      <NavLink className='sd-link' onClick={() => ToggleSidebar('Sclad')} >
                        <div className='cell'>
                          <CiBoxes style={{ 'marginRight': '15px' }} /> Склад
                        </div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className='sd-link' onClick={() => ToggleSidebar('AllOrders')} >
                        <div className='cell'>
                          <CiViewList style={{ 'marginRight': '15px' }} /> {`Все Заказы`}   {one.length > 0 && (
                            <span className='badge' style={{ 'marginLeft': '15px' }}>{one.length}</span>

                          )}

                        </div>
                      </NavLink>

                    </li>

                  </>
                  )}
                  {(admin.role == 'AllRights' || admin.role == 'Администратор') && (<>
                    <li>
                      <NavLink className='sd-link' onClick={() => ToggleSidebar('Employees')} >
                        <div className='cell'>
                          <CiUser style={{ 'marginRight': '15px' }} /> Сотрудники
                        </div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className='sd-link' onClick={() => ToggleSidebar('ShopKassas')} >
                        <div className='cell'>
                          <CiDollar style={{ 'marginRight': '15px' }} /> Кассы
                        </div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className='sd-link' onClick={() => ToggleSidebar('ShopIncasations')} >
                        <div className='cell'>
                          <CiBag1 style={{ 'marginRight': '15px' }} /> Инкосации
                        </div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className='sd-link' onClick={() => ToggleSidebar('ContrAgents')} >
                        <div className='cell'>
                          <CiMemoPad style={{ 'marginRight': '15px' }} /> Контрагенты
                        </div>
                      </NavLink>
                    </li>

                    <li>
                      <NavLink className='sd-link' onClick={() => ToggleSidebar('Products')} >
                        <div className='cell'>
                          <CiCoffeeCup style={{ 'marginRight': '15px' }} /> Продукты
                        </div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className='sd-link' onClick={() => ToggleSidebar('CoffeMashins')} >
                        <div className='cell'>
                          <MdCoffeeMaker style={{ 'marginRight': '15px' }} /> Кофемашины
                        </div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className='sd-link' onClick={() => ToggleSidebar('Reports')} >
                        <div className='cell'>
                          <CiEdit style={{ 'marginRight': '15px' }} /> Отчеты
                        </div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className='sd-link' onClick={() => ToggleSidebar('Smenas')} >
                        <div className='cell'>
                          <CiViewColumn style={{ 'marginRight': '15px' }} /> Смены
                        </div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className='sd-link' onClick={() => ToggleSidebar('Statistic')} >
                        <div className='cell'>
                          <CiWavePulse1 style={{ 'marginRight': '15px' }} /> Статистика
                        </div>
                      </NavLink>
                    </li>

                  </>
                  )}
                  <li>
                    <Button onClick={LogOut} text='Выход'></Button>

                  </li>
                </ul>
              </div>
            </div>
            <div className={`sidebar-overlay ${isOpen == true ? 'active' : ''}`} onClick={() => ToggleSidebar(dashboarPage)}></div>
          </div>
        )
        }
      </>
      )}


    </>
  )
}
export default LinksMenu
