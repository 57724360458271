import React, { useContext, useEffect, useState } from "react";
import style from "../Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../Button/Button.js";
import { QtdContext } from "../../../context/qtdContext.js";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import DatePicker from "react-datepicker";
import { confirmAlert } from 'react-confirm-alert'; // Import
import './react-confirm-alert.css'; // Import css

import "react-datepicker/dist/react-datepicker.css";
const AddContrAgentsModal = ({ }) => {


  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);

  const {contragents, setContrAgents, Notify,addContrAgentsModal, setAddContrAgentsModal, admins, setAdmins, admin } = useContext(QtdContext);
  const [newValue, setNeValue] = useState({
    name: null,
    email: null,
    inn:null,
    bank_name: null,
    dogovor_number: null,
    dogovor_date: null,
    bank_kpp: null,
    bank_rs: null,
    bank_ks: null,
  
  })
  const [loading, seloading] = useState(false)
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "container12464" && setAddContrAgentsModal(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };

  const [err, setErr] = useState({
    name: false,
    email: false,
    inn:false,
    bank_name: false,
    dogovor_number: false,
    dogovor_date: false,
    bank_kpp: false,
    bank_rs: false,
    bank_ks: false,
  
  })

  const editPrice = async () => {
    try {
      // e.preventDefault()

      let t = {
        name: false,
    email: false,
    inn:false,
    bank_name: false,
    dogovor_number: false,
    dogovor_date: false,
    bank_kpp: false,
    bank_rs: false,
    bank_ks: false,
      }

      if (!newValue.name) {
        t.name = `Выберите дану приема на работу!`

      }
      if (!newValue.email) {
        t.email = `Выберите логин!`

      }
      if (!newValue.inn) {
        t.inn = `Введите пароль!`

      }
      if (!newValue.bank_name) {
        t.bank_name = `Выберите телефон!`

      }
   

      setErr(t)
      if (
       
        !t.name &&
        newValue.name
      

      ) {
        seloading(true)
        // console.log(newValue)
        const newProd = await $api.post(`${BACKURL}/api/contragents/create`,
          { newValue: newValue, admin: admin.id }
        )
        // contragents, setContrAgents
        setContrAgents([...contragents, newProd.data])
        // setServOrders(newPosts)
        // setSclad(newPosts)

        // //Проверь правильность данных тут
        // setServerProd(newPosts)
        // console.log(newProd.data)
        // setModalInfo(false)
        seloading(false)
        Notify.addNotification({
          title: "Готово!",
          message: "Сотрудник успешно создан!",
          type: "success",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        // localStorage.setItem("petNew", JSON.stringify(newPosts));
        setAddContrAgentsModal(false)
        // alert('Добавлено!')
      }

    } catch (e) {
      console.log(e)
      seloading(false)
      Notify.addNotification({
        title: "Ошибка!",
        message: "Произошла непредвиденная ошибка!",
        type: "danger",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    }


  }
  const submit = async () => {
    confirmAlert({
      title: 'Создать сотрудника',
      message: 'Вы уверены, что хотите создать сотрудника?',
      buttons: [
        {
          label: 'Да!',
          onClick: async () => await editPrice()
        },
        {
          label: 'Отмена!',
          //   onClick: () => alert('Click No')
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };

  // const uniqueSerials = Array.from(new Set(sclad.map(item => item.kategory))); 
  return (
    <>
      {addContrAgentsModal && (
        <div onClick={closeModal} id="container12464" className={style.modal_container}>

<div className={style.borders}>
              <div className={style.inputtest}>

                <label >Наименование:</label>

                <input type="text" id="pass" name="pass" onChange={(event) => {
                  setNeValue({ ...newValue, name: event.target.value });
                }} />
                {err.name && (
                  <p style={{ color: "red" }}>{err.name}</p>
                )}
              </div>
              <div className={style.inputtest}>

<label >ИНН:</label>

<input type="text" id="pass" name="pass" onChange={(event) => {
  setNeValue({ ...newValue, inn: event.target.value });
}} />
{err.inn && (
  <p style={{ color: "red" }}>{err.inn}</p>
)}
</div>
<div className={style.inputtest}>

<label >email:</label>

<input type="text" id="pass" name="pass" onChange={(event) => {
  setNeValue({ ...newValue, email: event.target.value });
}} />
{err.email && (
  <p style={{ color: "red" }}>{err.email}</p>
)}
</div>
<div className={style.inputtest}>

<label >Наименование Банка:</label>

<input type="text" id="pass" name="pass" onChange={(event) => {
  setNeValue({ ...newValue, bank_name: event.target.value });
}} />
{err.bank_name && (
  <p style={{ color: "red" }}>{err.bank_name}</p>
)}
</div>
<div className={style.inputtest}>

<label >КПП Банка:</label>

<input type="text" id="pass" name="pass" onChange={(event) => {
  setNeValue({ ...newValue, bank_kpp: event.target.value });
}} />
{err.bank_kpp && (
  <p style={{ color: "red" }}>{err.bank_kpp}</p>
)}
</div>
<div className={style.inputtest}>

<label >Рс:</label>

<input type="text" id="pass" name="pass" onChange={(event) => {
  setNeValue({ ...newValue, bank_rs: event.target.value });
}} />
{err.bank_rs && (
  <p style={{ color: "red" }}>{err.bank_rs}</p>
)}
</div>
<div className={style.inputtest}>

<label >Кс:</label>

<input type="text" id="pass" name="pass" onChange={(event) => {
  setNeValue({ ...newValue, bank_ks: event.target.value });
}} />
{err.bank_ks && (
  <p style={{ color: "red" }}>{err.bank_ks}</p>
)}
</div>
<div className={style.inputtest}>

<label >Номер договора:</label>

<input type="text" id="pass" name="pass" onChange={(event) => {
  setNeValue({ ...newValue, dogovor_number: event.target.value });
}} />
{err.dogovor_number && (
  <p style={{ color: "red" }}>{err.dogovor_number}</p>
)}
</div>
<div className={style.inputtest}>

<label >Дата договора:</label>

<input type="text" id="pass" name="pass" onChange={(event) => {
  setNeValue({ ...newValue, dogovor_date: event.target.value });
}} />
{err.dogovor_date && (
  <p style={{ color: "red" }}>{err.dogovor_date}</p>
)}
</div>

              {/* <div className={style.inputtest}>

                <label >Выберите роль:</label>

                <select name="pets" id="prod"
                  onChange={(event) => {
                    setNeValue({ ...newValue, role: event.target.value });
                  }}
                >
                  <option value="">--Выберите категорию--</option>
                  <option value="Администратор">Администратор</option>
                  <option value="Доставщик">Доставщик</option>
                  <option value="Кладовщик">Кладовщик</option>
                  <option value="Бариста">Бариста</option>

                </select>
                {err.role && (
                  <p style={{ color: "red" }}>{err.role}</p>
                )}
              </div> */}

           

              <Button
                text="Создать"
                background="var(--primary-color)"
                color="white"
                // width="100%"
                onClick={submit}
              />

            

          </div>

        </div>

        // </div>
      )}
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
    </>
  );
};

export default AddContrAgentsModal;
