import React from "react";
import style from "./Home.module.css";
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { QtdContext } from "../../../context/qtdContext.js";
import Button from '../../../components/Button/Button.js'
import 'react-tabs/style/react-tabs.css';
// import { confirmAlert } from 'react-confirm-alert'; // Import
import { FcCancel, FcServices } from "react-icons/fc";
import $api from '../../../http/index.ts'
// import './react-confirm-alert.css'; // Import css
// import { BrowserRouter, Route, Routes, useNavigate, useLocation, Navigate } from "react-router-dom";

import { useEffect, useState, useContext, useCallback } from "react";
// import LinksMenu from "../../../components/Header/LinksMenu";
// import AddProductModal from "../../../components/Modals/AddProductModal";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import LinksMenu from "../../../components/Header/LinksMenu.js";
import Sclad from '../Sclad/Sclad.tsx';
import Shops from '../Shops/Shops.js';
import Employees from '../Employees/Employees.js';
import ShopPage from '../ShopPage/ShopPage.js';
import AllOrders from "../AllOrders/AllOrders.js";
import Products from "../Products/Products.js";
import ReportsPage from "../Reports/Report.tsx";
import CoffeeMashins from "../CoffeeMashins/CoffeeMashins.tsx";
import Smenas from "../Smenas/Smenas.js";
import Statistic from "../Statistic/Statistic.js";
import ContrAgents from "../ContrAgents/ContrAgents.js";
import ShopKassas from "../ShopKassas/ShopKassas.js";
import ShopIncasations from "../ShopIncasations/ShopIncasations.js";
const Dashboard = () => {

  const { setAvansReportsForms, setIncosations, shopKassas, setShopKassas, contragentsPrihods, setContrAgentsPrihods, contragents, setContrAgents, setTGorders, tgorders, setAvansReports, setCoffeeMashins, setSaleReportForms, setSmenas, setSaleReports, admin, setScladHistoryShops, setScladShops, setScladOrders, setReciepts, dashboarPage, setScladHistory, setAdmins, setSclad, setShops } = useContext(QtdContext);
  const [loading, setLoading] = useState(true)


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)

        const data = await $api.get(`${BACKURL}/api/products/getsclad`)
        // console.log(data.data, 'downlkoad')
        const sclad = data.data.filter(pr => pr.deleted == false)
        setSclad(sclad)

        const ShopKassas = async () => {
          // if (admin.role != 'Бариста') {
          const data = await $api.get(`${BACKURL}/api/shopkassas/getall`)
          // console.log(data);
          setShopKassas(data.data)
          console.log(data.data)
          // }
        }
        const ShopIncasations = async () => {
          // if (admin.role != 'Бариста') {
          const data = await $api.get(`${BACKURL}/api/incosations/getall`)
          // console.log(data);
          setIncosations(data.data)
          console.log(data.data)
          // }
        }

        const ScladHistory = async () => {
          if (admin.role != 'Бариста') {
            const data = await $api.get(`${BACKURL}/api/scladhistory/getall`)
            // console.log(data);
            setScladHistory(data.data)
          }
        }

        //no barista
        // }
        // if (admin.role == 'GOD') {

        const Shops = async () => {
          const data = await $api.get(`${BACKURL}/api/shop/getall`)
          // console.log(data);
          setShops(data.data)
          if (admin.role != 'Бариста' && admin.role != 'Кладовщик') {
            const data2 = await $api.post(`${BACKURL}/api/salereports/getall/1`, { pageSize: 15, shops: data.data.map(pr => pr.id) })
            // console.log(data.data);
            setSaleReports(data2.data)
          }
        }

        const ScladOrders = async () => {
          if (admin.role != 'Бариста') {
            const data = await $api.get(`${BACKURL}/api/scladorder/getall`)
            // console.log(data.data);
            setScladOrders(data.data)
          }

        }

        const Admins = async () => {
          // if (admin.role != 'Бариста') {
          const data = await $api.get(`${BACKURL}/api/admin/getall`)
          // console.log(data);
          setAdmins(data.data)
          // }

        }
        const Reciepts = async () => {
          if (admin.role != 'Бариста' && admin.role != 'Кладовщик') {

          }
          const data = await $api.get(`${BACKURL}/api/reciepts/getall`)
          setReciepts(data.data)
        }

        const ScladShops = async () => {
          if (admin.role != 'Бариста' && admin.role != 'Кладовщик') {
            const data = await $api.get(`${BACKURL}/api/scladshops/getall`)
            // console.log(data);
            setScladShops(data.data)
          }

        }
        const ContrAgents = async () => {
          // if (admin.role != 'Бариста' && admin.role != 'Кладовщик') {
          const data = await $api.get(`${BACKURL}/api/contragents/getall`)
          // console.log(data);
          setContrAgents(data.data)
          // }

        }
        const AvansReports = async () => {
          if (admin.role != 'Кладовщик') {
            const data = await $api.post(`${BACKURL}/api/avansreports/getall/1`, { pageSize: 5 })
            // console.log(data.data);
            setAvansReports(data.data)
          }

        }
        const ScladShopsHistory = async () => {
          if (admin.role != 'Бариста' && admin.role != 'Кладовщик') {
            const data = await $api.get(`${BACKURL}/api/scladhistoryshop/getall`)
            // console.log(data.data);
            setScladHistoryShops(data.data)
          }

        }
        const Smena = async () => {
          if (admin.role != 'Бариста' && admin.role != 'Кладовщик') {
            const data = await $api.get(`${BACKURL}/api/smena/getall`)
            // console.log(data.data);
            setSmenas(data.data)
          }

        }
        const SaleReportsForms = async () => {
          if (admin.role != 'Бариста' && admin.role != 'Кладовщик') {
            const data = await $api.get(`${BACKURL}/api/salereports/getallforms`)
            setSaleReportForms(data.data)
          }

        }
        const CoffeeMashins = async () => {

          if (admin.role != 'Бариста' && admin.role != 'Кладовщик') {
            const data = await $api.get(`${BACKURL}/api/coffeemashins/getall`)
            setCoffeeMashins(data.data)
          }

        }
        const TGorders = async () => {
          if (admin.role != 'Бариста' && admin.role != 'Кладовщик') {

          }
          const data = await $api.get(`${BACKURL}/api/tgorders/getall`)
          setTGorders(data.data)
        }
        const ContrAgentsPrihods = async () => {
          // if (admin.role != 'Кладовщик' ) {
          const data = await $api.post(`${BACKURL}/api/contragentsrashod/getall/1`, { pageSize: 5 })
          // console.log(data.data);
          setContrAgentsPrihods(data.data)
          // }

        }
        const AvansReportsForms = async () => {
          // seloading(true)
          if (admin.role != 'Кладовщик') {
            const data = await $api.post(`${BACKURL}/api/avansreportsforms/getall`, { pageSize: 5, page: 1 })
            // console.log(data.data);
            setAvansReportsForms(data.data)
          }
          // seloading(false)
    
    
        }
        const promiseall2 = await Promise.all([
          SaleReportsForms(),
          Smena(),
          Shops(),
          ScladHistory(),
          // admin.role != 'Бариста' ? ScladHistory() : console.log('Бариста'),
          ScladOrders(),
          Reciepts(),
          Admins(),
          ScladShops(),
          // SaleReports(),
          ScladShopsHistory()
        ])
        const promiseall3 = await Promise.all([
          CoffeeMashins(),
          TGorders(),
          ContrAgents(),
          AvansReports(),
          ContrAgentsPrihods(),
          ShopKassas(),
          ShopIncasations(),
          AvansReportsForms()]

        )
        setLoading(false)

      } catch (e) {
        setLoading(true)
        // console.log(e)
      } finally {
        // console.log('ok')

        setLoading(false)

      }

    }
    fetchData()
  }, []);

  return (<>
    {loading && (
      <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
    )}
    {!loading && (<>
      {dashboarPage === 'Home' && (
        <div className={style.container}>
          <div className={style.accwindow}>
            <h1>{admin.fullname}</h1>
          </div>
          {/* <div>
            <h1>Количество заказов</h1>
          </div>
          <div>
            <h1>Количество заказов</h1>
          </div> */}
        </div>

      )}
      {dashboarPage === 'Sclad' && (
        <Sclad></Sclad>
      )}
      {dashboarPage === 'ShopKassas' && (
        <ShopKassas></ShopKassas>
      )}
       {dashboarPage === 'ShopIncasations' && (
        <ShopIncasations></ShopIncasations>
      )}
      {dashboarPage === 'ContrAgents' && (
        <ContrAgents></ContrAgents>
      )}
      {dashboarPage === 'Employees' && (
        <Employees></Employees>
      )}

      {dashboarPage === 'Shops' && (
        <Shops></Shops>
      )}


      {dashboarPage.includes('ShopPage') && (
        <ShopPage></ShopPage>
      )}
      {dashboarPage.includes('AllOrders') && (
        <AllOrders></AllOrders>
      )}
      {dashboarPage.includes('Products') && (
        <Products></Products>
      )}
      {dashboarPage.includes('Smenas') && (
        <Smenas></Smenas>
      )}
      {dashboarPage.includes('Reports') && (
        <ReportsPage></ReportsPage>
      )}
      {dashboarPage.includes('CoffeMashins') && (
        <CoffeeMashins></CoffeeMashins>
      )}
      {dashboarPage.includes('Statistic') && (
        <Statistic></Statistic>
      )}
    </>
    )}

    {/* {/* </div> */}





    {/* </Routes>  */}

    {/* </BrowserRouter> */}


  </>
  );
};

export default Dashboard;
