import React, { useContext, useEffect, useState, useRef } from "react";
import style from "./Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../Button/Button.js";
import { QtdContext } from "../../../context/qtdContext.js";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';

import { RiDiscountPercentFill } from "react-icons/ri";
import { CiPercent } from "react-icons/ci";
import { confirmAlert } from 'react-confirm-alert'; // Import
import './react-confirm-alert.css'; // Import css
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { Store } from 'react-notifications-component';
import ProgressBar from "@ramonak/react-progress-bar";
import 'animate.css/animate.min.css';
import { BiSolidSave } from "react-icons/bi";
import { RiCheckboxBlankCircleLine } from "react-icons/ri";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import ComfirmReportModal from "./ComfirmReportModal.js";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FcOk, FcUp } from "react-icons/fc";
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { MdOutlineRadioButtonUnchecked, MdOutlineCheckCircleOutline } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';
import AddContrAgentsRashodModal from "../ContrAgents/AddContrAgentsRashodModal.js";
import EditContrAgentsRashodModal from "../ContrAgents/EditContrAgentsRashodModal.js";
const AvansReportForm = ({ id }) => {
  const {  avansReportsFormsModal, setAvansReportsFormsModal, incosations, setIncosations, contragentsPrihods, editCARashod, seteditCARashod, avansReports, setAvansReports, addCARashod, setaddCARashod, contragents, setAdmin, setComfirmReportModal, saleReports, setSaleReports, avansReportModal, setAvansReportModal, saleReportForms, saleReportSteps, setSaleReportSteps, scladShops, reciepts, sclad, setEditShopModal, admins, Notify, admin } = useContext(QtdContext);
  const [loading, seloading] = useState(false)
  console.log(avansReports)
  const formReports = avansReports.filter(pr=>pr.form_id==id)
  console.log(formReports)
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "dqwdqwdwqdwq" && setAvansReportsFormsModal(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };
  return (
    <>
      {/* <ReactNotifications /> */}
      {avansReportsFormsModal && (
        <div
          onClick={closeModal}
          id="dqwdqwdwqdwq" className={style.modal_container}
        // style={{backgroundImage: "url('../../../pages/auth/unnamed-2.jpg')"}}
        >
          <div className={style.borders2} style={{ height: 'inherit' }}>
            {/* <h2 id={'select'} style={{ color: "white" }}> {shop.name}</h2> */}
            <h5>{id}</h5>
            {/* <h3 style={{ color: "white" }}>{(totlaMoney - totalsale).toFixed()} РУБ</h3> */}
            <div className={style.nextitem}>
           

              < Button
                text="Выход"
                background="var(--primary-color)"
                color="white"
                width="100px"
                onClick={() => {
                  setAvansReportsFormsModal(false)

                }}
              />
            </div>
            <div style={{ display: 'flex', width: "100%", flexDirection: 'column', textAlign: "center" }} >
              <Tabs >
                <TabList>
                  <Tab style={{ background: 'none', border: 'none', color: "white" }} key={"Продажи"}><h5>Выручка</h5></Tab>

                  <Tab style={{ background: 'none', border: 'none', color: "white" }} key={'Счетчики'}><h5>Расходы</h5></Tab>
                  <Tab style={{ background: 'none', border: 'none', color: "white" }} key={'Остатки'}><h5>Касса</h5></Tab>
                </TabList>
                {/* counters */}
                <TabPanel>
                
                </TabPanel>
                <TabPanel></TabPanel>
                <TabPanel></TabPanel>




              </Tabs >


            </div>


          </div>

        </div >
      )}
      {
        loading && (
          <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
        )
      }
    
    

    </>
  )
}
export default AvansReportForm;